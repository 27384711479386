<template>
  <div class="error-log">
    <LoadingSpinner :isLoading="isLoading" />
    <div class="flex flex-col">
      <div class="w-full bg-action-bar px-2 py-2 clearfix">
        <div class="float-left flex flex-wrap">
          <div class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="log_type == 0 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeLog(0)">Alarm</div>
          <div v-if="user.role == 'admin'" class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="log_type == 1 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeLog(1)">Web Server</div>
          <div v-if="user.role == 'admin'" class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="log_type == 2 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeLog(2)">Frontend</div>
          <div v-if="user.role == 'admin'" class="rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer" v-bind:class="log_type == 3 ? 'bg-primary-500' : 'hover:bg-primary-400'" @click="changeLog(3)">Alarm Server</div>
        </div>
        <div class="float-right flex flex-wrap">
          <div class="datepicker-wrapper mx-2 mt-2 lg:mt-0">
            <date-picker :lang="date_picker_lang" :key="date_picker_langKey" v-model="date_range" type="date" range :editable="false" :clearable="false" @change="getLogs()" :disabled-date="disableDate">
              <template v-slot:footer="{ emit }">
                <div class="text-left hidden lg:block">
                  <button class="datepicker-action-btn mr-1" @click="selectCurrentWeek(emit)">Denna vecka</button>
                  <button class="datepicker-action-btn mr-1" @click="selectLastWeek(emit)">Förra veckan</button>
                </div>
              </template>
            </date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mt-6">
      <div v-show="log_type == 0">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="alarmColumns"
          :rows="alarmLogs"
          :search-options="{
            enabled: true,
          }"
          :pagination-options="{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20, 50, 100],
            dropdownAllowAll: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <button class="btn-blue-outline" @click="showAlarmError(props.row)">Visa</button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div v-show="log_type == 1">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="serverColumns"
          :rows="serverLogs"
          :search-options="{
            enabled: true,
          }"
          :pagination-options="{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20, 50, 100],
            dropdownAllowAll: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <button class="btn-blue-outline" @click="show(props.row.meta)">Visa</button>
            </span>
            <span v-else-if="props.column.field == 'url'">
              {{ getServerUrl(props.row.meta) }}
            </span>
            <span v-else-if="props.column.field == 'username'">
              {{ getServerUsername(props.row.meta) }}
            </span>
            <span v-else-if="props.column.field == 'role'">
              {{ getServerRole(props.row.meta) }}
            </span>
            <span v-else-if="props.column.field == 'error'">
              {{ getErrorMessage(props.row.meta) }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div v-show="log_type == 2">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="frontendColumns"
          :rows="frontendLogs"
          :search-options="{
            enabled: true,
          }"
          :pagination-options="{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20, 50, 100],
            dropdownAllowAll: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <button class="btn-blue-outline" @click="showFrontend(props.row.stack)">{{ $t("view") }}</button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
      <div v-show="log_type == 3">
        <vue-good-table
          styleClass="vgt-table"
          row-style-class="text-sm"
          :columns="tcpColumns"
          :rows="tcpLogs"
          :search-options="{
            enabled: true,
          }"
          :pagination-options="{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20, 50, 100],
            dropdownAllowAll: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <button class="btn-blue-outline" @click="showTCP(props.row)">Visa</button>
            </span>
            <span v-else-if="props.column.field == 'imei'">
              {{ getIMEI(props.row.meta) }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <modal name="modal-error-detail" class="modal-inner modal-error-detail" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="200" height="auto" :scrollable="true">
      <div class="px-3 py-3" v-if="errorInfo != null">
        <pre class="error-json text-xs whitespace-pre-wrap" v-html="errorInfo"></pre>
      </div>
    </modal>
    <ErrorReport :report="errorReport" :notEditable="true" />
  </div>
</template>

<script>
const MODAL_WIDTH = 700;

import ErrorReport from "@/components/modal/error_report";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/sv";
import "vue2-datepicker/locale/pl";
import "vue2-datepicker/index.css";

export default {
  name: "ErrorLog",
  title() {
    return this.$t("page_titles.error_log");
  },
  components: {
    ErrorReport,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      log_type: 0,
      alarmLogs: [],
      serverLogs: [],
      frontendLogs: [],
      tcpLogs: [],
      date_range: [],
      errorInfo: null,
      errorReport: null,
      date_picker_lang: this.$i18n.locale,
      date_picker_langKey: 0,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    tcpColumns() {
      return [
        { label: this.$t("tcp_table.timestamp"), field: "timestamp", sortable: true, formatFn: this.formatDate },
        { label: this.$t("tcp_table.imei"), field: "imei", sortable: false },
        { label: this.$t("tcp_table.error"), field: "message", sortable: true },
        { label: "", field: "action", sortable: false },
      ];
    },
    frontendColumns() {
      return [
        { label: this.$t("frontend_table.timestamp"), field: "createdAt", sortable: true, formatFn: this.formatDate },
        { label: this.$t("frontend_table.url"), field: "url", sortable: false },
        { label: this.$t("frontend_table.username"), field: "username", sortable: false },
        { label: this.$t("frontend_table.role"), field: "role", sortable: false },
        { label: this.$t("frontend_table.error"), field: "message", sortable: true },
        { label: "", field: "action", sortable: false },
      ];
    },
    serverColumns() {
      return [
        { label: this.$t("server_table.timestamp"), field: "timestamp", sortable: true, formatFn: this.formatDate },
        { label: this.$t("server_table.url"), field: "url", sortable: false },
        { label: this.$t("server_table.username"), field: "username", sortable: false },
        { label: this.$t("server_table.role"), field: "role", sortable: false },
        { label: this.$t("server_table.error"), field: "error", sortable: true },
        { label: "", field: "action", sortable: false },
      ];
    },
    alarmColumns() {
      return [
        { label: this.$t("alarm_table.serial_number"), field: "model", sortable: false },
        { label: this.$t("alarm_table.telematics_number"), field: "phone", sortable: false },
        { label: this.$t("alarm_table.imei"), field: "imei", sortable: false },
        { label: this.$t("alarm_table.customer"), field: "name", sortable: false },
        { label: this.$t("alarm_table.operation"), field: "unit_name", sortable: false },
        { label: this.$t("alarm_table.phone_number"), field: "contact_phone", sortable: false },
        { label: this.$t("alarm_table.email"), field: "email", sortable: false },
        { label: this.$t("alarm_table.timestamp"), field: "createdAt", sortable: true, formatFn: this.formatDate },
        { label: "", field: "action", sortable: false },
      ];
    },
  },

  methods: {
    getAlarmLogs() {
      this.isLoading = true;

      let start_date = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
      let end_date = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-error-log/${start_date}/${end_date}`)
        .then((response) => {
          this.alarmLogs = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleError(error);
        });
    },

    getServerLogs() {
      this.isLoading = true;

      let start_date = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
      let end_date = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/backend-log/${start_date}/${end_date}`)
        .then((response) => {
          this.serverLogs = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleError(error);
        });
    },

    getTCPLogs() {
      this.isLoading = true;

      let start_date = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
      let end_date = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/tcp-log/${start_date}/${end_date}`)
        .then((response) => {
          this.tcpLogs = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleError(error);
        });
    },

    getFrontendLogs() {
      this.isLoading = true;

      let start_date = new Date(this.moment(this.date_range[0]).startOf("day")).toISOString();
      let end_date = new Date(this.moment(this.date_range[1]).endOf("day")).toISOString();

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/frontend-log/${start_date}/${end_date}`)
        .then((response) => {
          this.frontendLogs = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.handleError(error);
        });
    },

    changeLog(v) {
      this.log_type = v;
      this.getLogs();
    },

    getLogs() {
      if (this.log_type == 0) this.getAlarmLogs();
      else if (this.log_type == 1) this.getServerLogs();
      else if (this.log_type == 2) this.getFrontendLogs();
      else if (this.log_type == 3) this.getTCPLogs();
    },

    getServerUrl(meta) {
      if (!_.isEmpty(meta) && meta.meta && meta.meta.req.url) {
        return meta.meta.req.url;
      } else return "";
    },

    getServerUsername(meta) {
      if (!_.isEmpty(meta) && meta.meta && meta.meta.req.user) {
        return meta.meta.req.user.username;
      } else return "";
    },

    getServerRole(meta) {
      if (!_.isEmpty(meta) && meta.meta && meta.meta.req.user) {
        return meta.meta.req.user.role;
      } else return "";
    },

    getErrorMessage(meta) {
      if (!_.isEmpty(meta) && meta.meta) {
        return meta.meta.error.message;
      } else if (!_.isEmpty(meta) && meta.error) {
        return meta.error.message;
      }
      else return "";
    },

    getIMEI(meta) {
      if (!_.isEmpty(meta) && meta) {
        return meta.imei || "-";
      } else return "";
    },

    syntaxHighlight(json) {
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      // eslint-disable-next-line
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function(match) {
        var cls = "number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      });
    },

    initDateRange() {
      const start = new Date(this.moment().startOf("isoWeek"));
      const end = new Date(this.moment().endOf("day"));
      this.date_range = [start, end];
    },

    disableDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    selectCurrentWeek(emit) {
      const start = new Date(this.moment().startOf("isoWeek"));
      const end = new Date(this.moment().endOf("day"));
      const date = [start, end];
      emit(date);
    },

    selectLastWeek(emit) {
      const start = new Date(
        this.moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
      );
      const end = new Date(
        this.moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
      );
      const date = [start, end];
      emit(date);
    },

    show(errorInfo) {
      let prettyPrint = JSON.stringify(errorInfo, undefined, 2);
      this.errorInfo = this.syntaxHighlight(prettyPrint);
      this.$modal.show("modal-error-detail");
    },

    showAlarmError(errorReport) {
      this.errorReport = {
        model: errorReport.model,
        phone: errorReport.phone,
        imei_number: errorReport.imei,
        customer_name: errorReport.name,
        unit_name: errorReport.unit_name,
        contact_phone: errorReport.contact_phone,
        contact_email: errorReport.email,
        message: errorReport.message,
      };
      this.$modal.show("error-report");
    },

    showFrontend(errorInfo) {
      this.errorInfo = errorInfo;
      this.$modal.show("modal-error-detail");
    },

    showTCP(errorInfo) {
      let prettyPrint = JSON.stringify(errorInfo, undefined, 2);
      this.errorInfo = this.syntaxHighlight(prettyPrint);
      this.$modal.show("modal-error-detail");
    },

    techUserDataFilter() {
      this.alarmColumns = [
        { label: "Serienummer", field: "model", sortable: false },
        { label: "Telematiknummer", field: "phone", sortable: false },
        { label: "IMEI", field: "imei", sortable: false },
        { label: "Timestamp", field: "createdAt", sortable: true, formatFn: this.formatDate },
        { label: "", field: "action", sortable: false },
      ];
    },
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;

    // hide customer data from tech user
    if (this.user.role == "tech") this.techUserDataFilter();

    this.initDateRange();
    this.getAlarmLogs();

    this.setPageTitle(`Error Logs`, "errorLogs");
  },
  watch: {
    "$i18n.locale"(newLocale) {
      document.title = this.$t("page_titles.error_log");
      this.date_picker_lang = newLocale;
      this.date_picker_langKey++;
    },
  },
};
</script>
