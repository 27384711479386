var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"error-log"},[_c('LoadingSpinner',{attrs:{"isLoading":_vm.isLoading}}),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"w-full bg-action-bar px-2 py-2 clearfix"},[_c('div',{staticClass:"float-left flex flex-wrap"},[_c('div',{staticClass:"rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer",class:_vm.log_type == 0 ? 'bg-primary-500' : 'hover:bg-primary-400',on:{"click":function($event){return _vm.changeLog(0)}}},[_vm._v("Alarm")]),(_vm.user.role == 'admin')?_c('div',{staticClass:"rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer",class:_vm.log_type == 1 ? 'bg-primary-500' : 'hover:bg-primary-400',on:{"click":function($event){return _vm.changeLog(1)}}},[_vm._v("Web Server")]):_vm._e(),(_vm.user.role == 'admin')?_c('div',{staticClass:"rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer",class:_vm.log_type == 2 ? 'bg-primary-500' : 'hover:bg-primary-400',on:{"click":function($event){return _vm.changeLog(2)}}},[_vm._v("Frontend")]):_vm._e(),(_vm.user.role == 'admin')?_c('div',{staticClass:"rounded bg-primary-300 py-1 px-3 text-white mx-2 font-bold text-sm cursor-pointer",class:_vm.log_type == 3 ? 'bg-primary-500' : 'hover:bg-primary-400',on:{"click":function($event){return _vm.changeLog(3)}}},[_vm._v("Alarm Server")]):_vm._e()]),_c('div',{staticClass:"float-right flex flex-wrap"},[_c('div',{staticClass:"datepicker-wrapper mx-2 mt-2 lg:mt-0"},[_c('date-picker',{key:_vm.date_picker_langKey,attrs:{"lang":_vm.date_picker_lang,"type":"date","range":"","editable":false,"clearable":false,"disabled-date":_vm.disableDate},on:{"change":function($event){return _vm.getLogs()}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
var emit = ref.emit;
return [_c('div',{staticClass:"text-left hidden lg:block"},[_c('button',{staticClass:"datepicker-action-btn mr-1",on:{"click":function($event){return _vm.selectCurrentWeek(emit)}}},[_vm._v("Denna vecka")]),_c('button',{staticClass:"datepicker-action-btn mr-1",on:{"click":function($event){return _vm.selectLastWeek(emit)}}},[_vm._v("Förra veckan")])])]}}]),model:{value:(_vm.date_range),callback:function ($$v) {_vm.date_range=$$v},expression:"date_range"}})],1)])])]),_c('div',{staticClass:"w-full mt-6"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.log_type == 0),expression:"log_type == 0"}]},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table","row-style-class":"text-sm","columns":_vm.alarmColumns,"rows":_vm.alarmLogs,"search-options":{
          enabled: true,
        },"pagination-options":{
          enabled: true,
          perPage: 20,
          perPageDropdown: [20, 50, 100],
          dropdownAllowAll: false,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('button',{staticClass:"btn-blue-outline",on:{"click":function($event){return _vm.showAlarmError(props.row)}}},[_vm._v("Visa")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.log_type == 1),expression:"log_type == 1"}]},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table","row-style-class":"text-sm","columns":_vm.serverColumns,"rows":_vm.serverLogs,"search-options":{
          enabled: true,
        },"pagination-options":{
          enabled: true,
          perPage: 20,
          perPageDropdown: [20, 50, 100],
          dropdownAllowAll: false,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('button',{staticClass:"btn-blue-outline",on:{"click":function($event){return _vm.show(props.row.meta)}}},[_vm._v("Visa")])]):(props.column.field == 'url')?_c('span',[_vm._v(" "+_vm._s(_vm.getServerUrl(props.row.meta))+" ")]):(props.column.field == 'username')?_c('span',[_vm._v(" "+_vm._s(_vm.getServerUsername(props.row.meta))+" ")]):(props.column.field == 'role')?_c('span',[_vm._v(" "+_vm._s(_vm.getServerRole(props.row.meta))+" ")]):(props.column.field == 'error')?_c('span',[_vm._v(" "+_vm._s(_vm.getErrorMessage(props.row.meta))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.log_type == 2),expression:"log_type == 2"}]},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table","row-style-class":"text-sm","columns":_vm.frontendColumns,"rows":_vm.frontendLogs,"search-options":{
          enabled: true,
        },"pagination-options":{
          enabled: true,
          perPage: 20,
          perPageDropdown: [20, 50, 100],
          dropdownAllowAll: false,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('button',{staticClass:"btn-blue-outline",on:{"click":function($event){return _vm.showFrontend(props.row.stack)}}},[_vm._v(_vm._s(_vm.$t("view")))])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.log_type == 3),expression:"log_type == 3"}]},[_c('vue-good-table',{attrs:{"styleClass":"vgt-table","row-style-class":"text-sm","columns":_vm.tcpColumns,"rows":_vm.tcpLogs,"search-options":{
          enabled: true,
        },"pagination-options":{
          enabled: true,
          perPage: 20,
          perPageDropdown: [20, 50, 100],
          dropdownAllowAll: false,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('button',{staticClass:"btn-blue-outline",on:{"click":function($event){return _vm.showTCP(props.row)}}},[_vm._v("Visa")])]):(props.column.field == 'imei')?_c('span',[_vm._v(" "+_vm._s(_vm.getIMEI(props.row.meta))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)]),_c('modal',{staticClass:"modal-inner modal-error-detail",attrs:{"name":"modal-error-detail","transition":"pop-out","width":_vm.modalWidth,"focus-trap":true,"min-height":200,"height":"auto","scrollable":true}},[(_vm.errorInfo != null)?_c('div',{staticClass:"px-3 py-3"},[_c('pre',{staticClass:"error-json text-xs whitespace-pre-wrap",domProps:{"innerHTML":_vm._s(_vm.errorInfo)}})]):_vm._e()]),_c('ErrorReport',{attrs:{"report":_vm.errorReport,"notEditable":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }